import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer} from '@angular/platform-browser';

import { faChevronRight, faEye, faGlobe } from '@fortawesome/free-solid-svg-icons';
import { faLinkedin, faFacebook, faTwitter } from '@fortawesome/free-brands-svg-icons';

@Component({
  selector: 'app-startup-card',
  templateUrl: './startup-card.component.html',
  styleUrls: ['./startup-card.component.less']
})
export class StartupCardComponent implements OnInit {

  @Input() card: any;
  @Input() prices = false;
  tags = [];
  props = [];
  buttons = [];
  number_of_users = null;
  showingVideo = false; 
  watchUrl = null;

  faChevronRight = faChevronRight;
  faEye = faEye;

  constructor(public sanitizer: DomSanitizer) { }

  ngOnInit(): void {
    for (const x of (this.card.age_group || [])) {
      this.props.push('AGES ' + x);
    }
    this.props.push('Subject: ' + this.card.learning_subjects.slice(0, 3).join(', '));
    this.tags = this.card.learning_skills.slice(0, 3);
    if (this.card.website) {
      let href = this.card.website;
      if (href.indexOf('http') !== 0) {
        href = 'http://' + href;
      }
      this.buttons.push({
        href: href,
        icon: faGlobe
      });
    }
    if (this.card.facebook) {
      this.buttons.push({
        href: this.card.facebook,
        icon: faFacebook
      });
    }
    if (this.card.twitter) {
      this.buttons.push({
        href: this.card.twitter,
        icon: faTwitter
      });
    }
    if (this.card.linkedin) {
      this.buttons.push({
        href: this.card.linkedin,
        icon: faLinkedin
      });
    }
    if (this.card.number_of_users) {
      this.number_of_users = (<number>this.card.number_of_users).toLocaleString('en-US', {maximumFractionDigits: 0, useGrouping: true});
    }
    if (this.card.watch && this.card.watch.length) {
      this.watchUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.card.watch[0]);
    }
  }

}
