import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { StartupCardsComponent } from './startup-cards/startup-cards.component';
import { StartupCardComponent } from './startup-card/startup-card.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgxUsefulSwiperModule } from 'ngx-useful-swiper';
import { WrapperComponent } from './wrapper/wrapper.component';
import { VersionComponent } from './version/version.component';

@NgModule({
  declarations: [
    AppComponent,
    StartupCardsComponent,
    StartupCardComponent,
    WrapperComponent,
    VersionComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FontAwesomeModule,
    NgxUsefulSwiperModule,
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
