import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { switchMap } from 'rxjs/operators';

@Component({
  selector: 'app-wrapper',
  templateUrl: './wrapper.component.html',
  styleUrls: ['./wrapper.component.less']
})
export class WrapperComponent implements OnInit {

  src = null;

  constructor(public sanitizer: DomSanitizer, private activatedRoute: ActivatedRoute) {
    activatedRoute.data.subscribe((data: any) => {
      if (data.url) {
        this.src = this.sanitizer.bypassSecurityTrustResourceUrl(data.url);
      }
    });
  }

  ngOnInit(): void {
  }

}
