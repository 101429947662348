<div class='header'>
    <a href='/'>
        <img src='assets/img/logo.png' class='logo'>
    </a>
</div>
<div class='showcase'>
    <ng-container *ngFor='let card of cards'>
        <div class='thumbnail' *ngIf='enabled(card)'>
            <h1 (click)='open_slideshow(i)'>{{card.name}}</h1>
            <img class='thumbnail' [src]='card.logo' (click)='open_slideshow(card.name)'>
        </div>  
    </ng-container>
</div>
<ng-container *ngIf='slideshow'>
    <div class='modal'>
        <div class='overlay'></div>
        <div class='slideshow'>
            <swiper [config]='swiperConfig' #usefulSwiper> 
                <div class="swiper-wrapper">
                    <ng-container *ngFor='let card of cards'>
                        <div class="swiper-slide" *ngIf='enabled(card)'>
                            <app-startup-card [card]='card' [prices]='config.prices'></app-startup-card>
                        </div>
                    </ng-container>
                </div>
                <!-- Add Pagination -->
                <div class="swiper-pagination"></div>
                <!-- Add Arrows -->
                <div class="swiper-button-next"></div>
                <div class="swiper-button-prev"></div>
            </swiper>
        </div>
        <div class='close' (click)='close_slideshow()'>&times;</div> 
    </div>
</ng-container>
