<!--pre>{{card | json}}</pre-->
<div class='card'>
    <div class='bg'></div>
    <div class='title'>{{card.name}}</div>
    <div class='row'>
        <div class='left'>
            <div class='top'>
                <div class='picture' [style.background-image]='"url(" + card.picture + ")"'></div>
                <div class='props'>
                    <div class='prop' *ngFor='let prop of props'>{{ prop }}</div>
                    <div class='tags'>
                        <div class='tag' *ngFor='let tag of tags'>{{ tag }}</div>
                    </div>
                    <div class='try' *ngIf='card.try'>TRY <fa-icon [icon]='faChevronRight'></fa-icon></div> 
                </div>  
            </div>
            <div class='bottom'>
                <div class='text2'>
                    <span>{{ card.website_text_1 }}</span>
                </div>
                <div class='text2'>
                    <h2>Business Model</h2>
                    <div class='pricing' *ngIf='prices'>{{ card.pricing }}</div>
                    <div class='pricing' *ngIf='!prices'>{{ card.pricing_2 }}</div>
                </div>   
            </div>
        </div>
        <div class='right'>
            <div class='text1'>
                <div class='header'>{{ card.headline_text_2 }}</div>
                {{ card.website_text_2 }}
                <div class='research' *ngIf='card.research_link'>+ RESEARCH</div> 
            </div>
            <div class='traction'>
                <div class='header'>{{ card.headline_text_3 }}</div>
                <div class='content' *ngIf='card.content'>{{card.website_text_3}}</div>
            </div>
            <div class='external'>
                <div class='watch' (click)='showingVideo=true' *ngIf='card.watch'><fa-icon [icon]='faEye'></fa-icon> WATCH</div>
                <div class='buttons'>
                    <a class='button' *ngFor='let button of buttons' [href]='button.href' target='_blank'><fa-icon [icon]='button.icon'></fa-icon></a>
                </div>
            </div>
        </div>
    </div>
    <img class='logo' [src]='card.logo'>
    <div class='youtube' *ngIf='showingVideo'>
       <div class='close' (click)='showingVideo=false'>&times;</div>
       <iframe [src]="watchUrl"
               frameborder="0" 
               allow="autoplay; fullscreen"></iframe>
    </div>
</div>