import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SwiperComponent } from 'ngx-useful-swiper';
import { fromEvent, Subscription } from 'rxjs';
import { filter, first, switchMap } from 'rxjs/operators';
import { SwiperOptions } from 'swiper';

@Component({
  selector: 'app-startup-cards',
  templateUrl: './startup-cards.component.html',
  styleUrls: ['./startup-cards.component.less']
})
export class StartupCardsComponent implements OnInit {

  @ViewChild('usefulSwiper',{static: false}) usefulSwiper: SwiperComponent;
  
  cards = [];
  config: any = {};
  slideshow = false;
  swiperConfig: SwiperOptions = {
    pagination: { el: '.swiper-pagination', clickable: true },
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev'
    },
    spaceBetween: 30,
    loop: true,
  };
  l = console.log;
  sub: Subscription;

  constructor(private http: HttpClient, private activatedRoute: ActivatedRoute) {
    activatedRoute.data.pipe(
      switchMap((data) => {
        this.config = data;
        console.log('CONFIG: ', data);
        return http.get('assets/startups-table.json');
      })
    ).subscribe((cards: any[]) => { 
      this.cards = cards; 
    });
  }

  ngOnInit(): void {
  }

  enabled(card) {
    return (this.config.allCards || 
        (this.config.enabled && this.config.enabled.indexOf(card.name) >= 0)) &&
      (!this.config.disabled || this.config.disabled.indexOf(card.name) === -1);
  }

  open_slideshow(name) {
    this.slideshow = true;
    let i = 0;
    for (const card of this.cards) {
      if (!this.enabled(card)) {
        continue;
      }
      if (card.name === name) {
        break;
      }
      i++;
    }
    this.swiperConfig.initialSlide = i;
    this.sub = fromEvent(window, 'keyup').pipe(
      filter((event: KeyboardEvent) => {
        return event.key === 'Escape';
      }),
      first()
    ).subscribe(() => {this.close_slideshow();});
    setTimeout(() => {
      this.usefulSwiper.swiper.update();
    }, 100);
  }

  close_slideshow() {
    this.slideshow = false;
    this.sub.unsubscribe();
  }
}
