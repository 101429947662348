import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { StartupCardsComponent } from './startup-cards/startup-cards.component';
import { VersionComponent } from './version/version.component';
import { WrapperComponent } from './wrapper/wrapper.component';

const routes: Routes = [
  { path: 'lahzeerai2Kah3aa/cards', component: StartupCardsComponent,
    data: {
      allCards: true,
      prices: true
    }
  },
  { path: 'ga5aiPheiF0ohhae/cards-no-prices', component: StartupCardsComponent,
    data: {
      allCards: true,
      prices: false
    }
  },
  { path: 'kie3aiRaeK2rua5o/andy', component: StartupCardsComponent,
    data: {
      allCards: true,
      disabled: ['Oneder'],
      prices: false
    }
  },
  { path: '3dcoderz', component: WrapperComponent,
    data: {
      url: 'https://3dcoderz.wordpress.com/'
    }
  },
  { path: 'bitbybit', component: WrapperComponent,
    data: {
      url: 'https://bitbybit.x10ed.com'
    }
  },
  { path: 'chessmatec', component: WrapperComponent,
    data: {
      url: 'https://www.chessadmin.com/buy?type=yearly'
    }
  },
  { path: 'demo', component: WrapperComponent,
    data: {
      url: 'https://player.vimeo.com/video/434820474'
    }
  },
  { path: 'version', component: VersionComponent},
  { path: '', component: WrapperComponent,
    data: {
      url: 'https://spark.adobe.com/page/7ZI9hdrMx8c3V/'
    }
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
